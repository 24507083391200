function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

// TODO: Sort this out, there must be a proper solution !!!
export function formatDateTime(dateTime) {
  const day = appendLeadingZeroes(dateTime.getDate());
  const month = appendLeadingZeroes(dateTime.getMonth() + 1);
  const year = dateTime.getFullYear();
  const hour = appendLeadingZeroes(dateTime.getHours());
  const minute = appendLeadingZeroes(dateTime.getMinutes());

  return `${day}.${month}.${year} ${hour}:${minute}`;
}
