<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('menu.myProfile') }}
      </template>
    </RMPageHeader>
    <div class="row">
      <div class="col-xl-12">
        <b-tabs nav-class="mb-3" pills>
          <b-tab active>
            <template slot="title">
              <span class="d-sm-none">{{ $t("myProfile.general") }}</span>
              <span class="d-sm-block d-none">{{ $t("myProfile.general") }}</span>
            </template>
            <b-card-body>
              <b-form @submit.stop.prevent>
                <div class="form-group">
                  <label>{{ $t("myProfile.name") }}</label>
                  <b-input
                    disabled
                    type="text"
                    class="form-control"
                    id="firstLastName"
                    :value="this.account.user.name"
                  />
                </div>
                <div class="form-group">
                  <label>{{ $t("myProfile.email") }}</label>
                  <b-input
                    disabled
                    type="text"
                    class="form-control"
                    id="userEmail"
                    :value="this.account.user.email"
                  />
                </div>
                <div class="form-group">
                  <label>{{ $t("myProfile.code") }} <i id="code-tooltip" class='fa fa-question-circle'/></label>
                  <b-input
                    disabled
                    type="text"
                    class="form-control"
                    id="code"
                    :value="userData.code"
                  />
                  <b-tooltip target="code-tooltip" triggers="hover">
                    {{ $t("myProfile.uniqueCodeTooltip") }}
                  </b-tooltip>
                </div>
                <div class="form-group">
                  <label>{{ $t("myProfile.address") }} *</label>
                  <b-input
                    type="text"
                    class="form-control"
                    id="street"
                    placeholder=""
                    minlength="3"
                    required
                    v-model="userData.street"
                  />
                </div>
                <div class="form-group">
                  <label>{{ $t("myProfile.postNumber") }} *</label>
                  <b-input
                    type="number"
                    class="form-control"
                    id="postNumber"
                    placeholder=""
                    required
                    v-model="userData.postNumber"
                  />
                </div>
                <div class="form-group">
                  <label>{{ $t("myProfile.post") }} *</label>
                  <b-input
                    type="text"
                    class="form-control"
                    id="post"
                    placeholder=""
                    minlength="3"
                    required
                    v-model="userData.post"
                  />
                </div>
                <div class="form-group">
                  <label>{{ $t("myProfile.country") }} *</label>
                  <b-select class="form-control" v-model="userData.country" id="country">
                    <option value="SI" selected>{{ $t("countries.si") }}</option>
                  </b-select>
                </div>

                <div class="form-group">
                  * {{ $t("myProfile.whyDataNeeded") }}
                </div>
                <button type="submit" @click="saveUserData" :disabled="!canSaveUserData" class="btn btn-primary m-2">
                  {{ $t("myProfile.saveChanges") }}
                </button>
                <button @click="cleanUserData" class="btn btn-warning m-2">
                  {{ $t("myProfile.cleanMyData") }}
                </button>
              </b-form>
            </b-card-body>
          </b-tab>
          <b-tab>
            <template slot="title">
              <span class="d-sm-none">{{ $t("myProfile.myPurchases") }}</span>
              <span class="d-sm-block d-none">{{ $t("myProfile.myPurchases") }}</span>
            </template>
            <b-card-body>
              <b-table hover :items="items" :fields="tableFields" :busy="showBusy">
                <template #table-busy>
                  <div class="text-center m-8" v-if="loadingPayments">
                    <b-spinner class="align-middle"></b-spinner>
                    <span class="m-l-10">{{ $t("common.loading") }}</span>
                  </div>
                  <div class="text-center m-8" v-if="noPayments">
                    <span class="m-l-10">{{ $t("myProfile.noPurchasesYet") }}</span>
                  </div>
                </template>
              </b-table>
            </b-card-body>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {paymentsService} from "@/_services/payments.service";
import {userService} from "@/_services";
import router from "@/config/PageRoutes";
import {formatDateTime} from "@/_helpers/date_helper";
import {handleError} from "@/_helpers/api";
import {showSweetAlert} from "@/_helpers/api";
import RMPageHeader from "@/components/common/RMPageHeader.vue";

export default {
  name: "Profile",
  components: {RMPageHeader},
  data() {
    return {
      showBusy: false,
      loadingPayments: false,
      noPayments: false,
      items: [],
      userData: {
        street: "",
        postNumber: "",
        post: "",
        country: "SI",
        code: "",
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    tableNumberOfCredits() {
      return this.$t("myProfile.numberOfCredits");
    },
    tableFields() {
      return [
        {
          key: "purchase_date",
          label: this.$t("myProfile.dateOfPurchase"),
        },
        {
          key: "num_carrots",
          label: this.tableNumberOfCredits,
          sortable: true
        },
        {
          key: "price",
          label: this.$t("myProfile.price"),
          sortable: true
        },
        {
          key: "status",
          label: this.$t("myProfile.status"),
          sortable: true
        },
      ];
    },
    canSaveUserData() {
      if (this.userData === undefined || this.userData === {})
        return false;

      let streetFilled = "street" in this.userData && this.userData.street != null && this.userData.street !== "" && this.userData.street.length >= 3;
      let postNumberFilled = "postNumber" in this.userData && this.userData.postNumber != null && this.userData.postNumber !== "";
      let postFilled = "post" in this.userData && this.userData.post != null && this.userData.post !== "" && this.userData.post.length >= 3;
      let countryFilled = "country" in this.userData && this.userData.country != null && this.userData.country !== "";

      return streetFilled && postNumberFilled && postFilled && countryFilled;
    },
  },
  methods: {
    loadPayments() {
      this.showBusy = true;
      this.loadingPayments = true;
      this.noPayments = false;

      this.items = [];
      paymentsService
          .listPayments()
          .then((data) => {
            if (data.payments.length === 0) {
              this.noPayments = true;
              this.loadingPayments = false;
              return;
            }

            for (let i = 0; i < data.payments.length; i++) {
              const item = data.payments[i];

              let date = formatDateTime(new Date(item.paymentDate));

              this.items.push({
                num_carrots: item.numCarrots,
                price: `${item.price} ${item.currency}`,
                purchase_date: date,
                status: this.$t("messages.PAYMENT-SUCCESSFUL"),
              });
            }

            this.showBusy = false;
            this.loadingPayments = false;
            this.noPayments = false;
          })
          .catch((error) => handleError(error, router, this._vm));
    },
    getUserData() {
      userService.get().then(user => {
        if (user.street !== undefined) {
          this.userData.street = user.street
        }

        if (user.postNumber !== undefined) {
          this.userData.postNumber = user.postNumber;
        }

        if (user.post !== undefined) {
          this.userData.post = user.post;
        }

        if (this.userData.country !== undefined && this.userData.country !== "") {
          this.userData.country = user.country;
        }

        if (user.code !== undefined) {
          this.userData.code = user.code;
        }
      })
    },
    saveUserData() {
      userService.update({
        street: this.userData.street,
        postNumber: this.userData.postNumber,
        post: this.userData.post,
        country: this.userData.country,
      }).then(() => {
        showSweetAlert("S-CHANGES-SAVED", this);
      }).catch((error) => handleError(error, router, this._vm));
    },
    cleanUserData() {
      userService.cleanData().then(() => {
        this.userData.street = "";
        this.userData.post = "";
        this.userData.postNumber = "";
        this.userData.country = "";
        showSweetAlert("S-CHANGES-SAVED", this);
      }).catch((error) => handleError(error, router, this._vm));
    },
  },
  created() {
    this.getUserData();
    this.loadPayments();
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>
